@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: libre;
  src: url("./assets/fonts/LibreFranklin-VariableFont_wght.ttf");
}

html,
body {
  font-family: libre;
}

.bg-grad {
  background: linear-gradient(to top, rgba(13, 13, 79, 1), rgba(1, 1, 65, 0.1)),
    url("./assets/imgs/hero.png");
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

li {
  margin: 0;
  padding: 0;
}

.scroll {
  height: 60vh;
  overflow: scroll;
  overflow-x: hidden;

}

body::-webkit-scrollbar {
  width: 1em;
}

.scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scroll::-webkit-scrollbar-thumb {
  background-color: red;
  outline: 1px solid slategrey;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}